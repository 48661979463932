<template>
    <div class="Standard2Description" v-if="countText && countText !== '' || countTextList && countTextList.length !== 0">
      <div class="top2_nav"><span></span>结果</div>
      <div class="Standard2Deatil" v-if="countText && countText !== ''">
        <div class="result2Style" :style="styleA">
           {{ countText}}
        </div>
      </div>
      <div class="Standard2Deatil" v-if="countTextList && countTextList.length !== 0 ">
        <div class="result2Style" :style="styleA" v-for="item in countTextList" :key="item">
           {{ item }}
        </div>
      </div>
    </div>
    
  </template>
  
  <script>
  export default {
    name: "References",
    props: {
      countText: {
        type: String,
        required: true,
      },
      countTextList: {
        type: Array,
        required: true,
      },
      styleA: {
        type: Object,
        required: false
      }
    },
    methods: {
      // 开参考文献链接
      openLink(u){
        if (!u) {
          return
        }
        //获取系统版本
        let userAI = navigator.userAgent;
        const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
        const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
          // alert('安卓操作系统')
          // 这个是安卓操作系统
          window.open(u)
        }
        if (isIOS) {
          // 这个是ios操作系统
          // alert('ios操作系统')
          window.location.href = u
        }
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .Standard2Description{
    margin-top:10px;
    .top2_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px; 
      span {
        display: inline-block;
        width: 5px;
        height: 20px;
        background: #fe9712;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top2_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .Standard2Deatil{
    width:100%;
    padding: 15px 10px;
    padding-bottom:9px;
    //   height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    .result2Style{
      margin-bottom:6px;
      font-size:20px;
      color: #2063aa;
      font-weight:600;
      display: flex;
      line-height: 30px;
      justify-content: center;
    }
    .references{
      margin-bottom:15px
    }
  }
  </style>
<template>
  <div class="StandardDescription" v-if="referencesData && referencesData.length > 0">
    <div class="top_nav"><span></span>参考文献</div>
    <div class="StandardDeatil2">
      <!-- <span v-html="item.value">
      </span> -->
      <span v-for="(item,index) in referencesData" :key="item.title" >
            <div v-if="index !== 0" style="margin: 0 -10px 10px -10px;border-top: 1px solid #E7E7E7"></div>
              <!-- <div style="text-align:justify;font-size: 14px;color:#595959;word-break: keep-all;word-wrap: break-word;white-space: pre-wrap;word-break:break-all" v-html="item.title" class="references" @click="openLink(item.link)">
              </div> --> 
              <div class="references" @click="openLink(item.link)">{{item.title}}</div>
            </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "References",
  props: {
    referencesData: {
      type: Array,
      required: true,
    }
  },
  methods: {
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.StandardDescription{
  margin-top:10px;
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .StandardDeatil{
    width:100%;
    padding:15px 10px;
    //   height: 50px;
    background-color: #fff;
    box-sizing: border-box;
  }
}
.top_nav{
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom:10px;
  span {
    display: inline-block;
    width: 5px;
    height: 15px;
    background: #259beb;
    margin-right: 5px;
  }
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow-wrap: normal; /* 或者使用 word-wrap: break-word; */
    margin-bottom:15px;
    font-size: 14px;
    color:#595959;
    text-align:justify;
  }
}
</style>
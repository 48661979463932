<template>
  <div v-if="explainData && explainData.length > 0">
    <div v-for="(item,index) in explainData" :key="index" class="StandardDescription">
      <div class="top_nav"><span></span>{{item.title}}</div>
      <div class="StandardDeatil">
            <span v-html="item.value">
            </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "formulaExplain",
  props: {
    explainData: {
      type: Array,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.StandardDescription{
  margin-top:10px;
.top_nav{
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom:10px;
span {
  display: inline-block;
  width: 5px;
  height: 15px;
  background: #259beb;
  margin-right: 5px;
}
}
.StandardDeatil{
  width:100%;
  padding:15px 10px;
//   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
}
}
.top_nav{
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom:10px;
  span {
    display: inline-block;
    width: 5px;
    height: 15px;
    background: #259beb;
    margin-right: 5px;
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>